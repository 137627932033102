import React from 'react';

const AdvItem = ({ title, desc }) => {
  return (
    <div className='adv_item'>
      <h4>{title}</h4>
      <p>{desc}</p>
    </div>
  );
};

export default AdvItem;
