/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { IoIosCall } from 'react-icons/io';
import { HiOutlineLocationMarker } from 'react-icons/hi';

import './style.css';
import { Link } from 'react-router-dom';
import { PATHS } from '../../constants/paths';

const Footer = () => {
  return (
    <div className='footer bg'>
      <div className='footer_container'>
        <div className='footer_container_item logo_area'>
          <div className='footer_logo'>
            <img src='/assets/images/logo-remove-background.png' alt='' />
          </div>
          <p>
            شركة تقدم مختلف الخدمات للعناية في المركبات التي تتميز في غسيل
            السيارات المتنقل، كما أنها تتميز بتشغيل الأيدي العاملة المدربة.
          </p>
        </div>
        <div className='footer_container_item titles_area'>
          <div className='footer-title'>اتصل بنا </div>
          <div className='footer_item_group'>
            <div className='footer_item_Icon'>
              <HiOutlineLocationMarker />
            </div>
            <div className='footer_item_text'>
              الرياض | العليا | برج مدار الطابق الثاني
            </div>
          </div>
          <div className='footer_item_group'>
            <a target='_blank' href={'mailto:Info@amyalservice.com'}>
              <div className='footer_item_Icon'>
                <TfiEmail />
              </div>
              <div className='footer_item_text'>Info@amyalservice.com</div>
            </a>
          </div>
          <div className='footer_item_group'>
            <a target='_blank' href={'tel:+966595482309'}>
              <div className='footer_item_Icon'>
                <IoIosCall />
              </div>
              <div className='footer_item_text'>2309 548 59 966+</div>
            </a>
          </div>
        </div>
        <div className='footer_container_item pages_area'>
          <div className='footer-title'> راوبط سريعة</div>
          <ul className='list'>
            <li>
              <Link to={PATHS.ABOUT} title=''>
                من نحن
              </Link>
            </li>
            <li>
              <Link to={PATHS.SERVICES.CAR_QUEUING} title=''>
                (خدمة الڤاليه )اصطفاف المركبات
              </Link>
            </li>
            <li>
              <Link to={PATHS.SERVICES.CAR_WASH} title=''>
                خدمة غسيل السيارات
              </Link>
            </li>
            <li>
              <Link to={PATHS.CONTACT} title=''>
                تواصل معنا
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer-sosmed social_area'>
          <a
            target='_blank'
            href={
              'https://twitter.com/amyal_service/status/1704842374961156108?s=46'
            }
            title=''>
            <div className='item'>
              <FaTwitter />
            </div>
          </a>
          <a
            target='_blank'
            href={
              'https://www.instagram.com/p/CxcvbljqzYx/?igshid=MzRlODBiNWFlZA%3D%3D'
            }
            title=''>
            <div className='item'>
              <FaInstagram />
            </div>
          </a>
          <a
            target='_blank'
            href={'https://www.linkedin.com/company/amyalservice/'}
            title=''>
            <div className='item'>
              <FaLinkedinIn />
            </div>
          </a>
          <a
            target='_blank'
            href={
              'https://www.facebook.com/people/%D8%A3%D9%85%D9%8A%D8%A7%D9%84-%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-Amyal-Service/61550804476172/?paipv=0&eav=Afa2dndkRcYNze_dDO_tAe4bpvcKS4uWF3cB8VO-CDLMbrI8jYUelTfjSirCVeHUb5g&_rdr'
            }
            title=''>
            <div className='item'>
              <FaFacebook />
            </div>
          </a>
        </div>
      </div>

      <div className='fcopy'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-12'>
              <p className='ftex'>
                أمــــــــيــــــــــــال خدمات - جميع الحقوق محفوظة &copy;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
