/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import ServiceCard from './ServiceCard';
import './style.css';
import CarWashMainSection from '../../CarWashPage';
import CarQueuingMain from '../../CarQueuingPage';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const ServicesSection = () => {
  const [dataShow, setDataShow] = useState();

  const { width } = useWindowDimensions();

  const onClickHandler = (num) => {
    setDataShow(num);
  };

  return (
    <>
      <div className='section wedo '>
        <div className='container'>
          <div className='row service_center'>
            <ServiceCard
              onClick={onClickHandler}
              id={0}
              title={'خدمة الڤاليه (اصطفاف المركبات)'}
              desc={'يتم توفير فريق خاص في إدارة وتنظيم عملية اصطفاف المركبات.'}
              image={'assets/images/queuing.jpg'}
            />
            {width < 765 && dataShow == 0 && <CarQueuingMain />}
            <ServiceCard
              onClick={onClickHandler}
              id={1}
              title={'خدمات غسيل السيارات'}
              desc={'نقدم خدمات غسيل وتلميع المركبات بواسطة فريق بخبرات عالية.'}
              image={'assets/images/cleancar2.gif'}
            />
            {width < 765 && dataShow == 1 && <CarWashMainSection />}
          </div>
        </div>
      </div>
      {width > 765 && dataShow == 0 && <CarQueuingMain />}
      {width > 765 && dataShow == 1 && <CarWashMainSection />}
    </>
  );
};

export default ServicesSection;
