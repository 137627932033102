import React from 'react';
import ContactForm from '../../components/ContactForm';
import BreadCrumb from '../../components/BreadCrumb';

const ContactPage = () => {
  return (
    <>
      <BreadCrumb page={' تواصل معنا'} />
      <ContactForm />
    </>
  );
};

export default ContactPage;
