import React from 'react';
import ServicesSection from '../../components/sections/HomePage/SevicesSection';
import WhySection from '../../components/sections/HomePage/WhySection';
import CustomersSection from '../../components/sections/HomePage/CustomersSection';
import AdvantagesSection from '../../components/sections/HomePage/ِAdvantagesSection';
import Slider from '../../components/Slider';

const HomePage = () => {
  return (
    <>
      <Slider />
      <div className='hero'>
        <p>
          أميال خدمات لـ غسيل السيارات المفهوم الجديد لغسيل السيارات <br />
          .. نختصر المسافات..
          <br /> نجيك بمكانك ونغسل سيارتك بأسطول متحرك باحترافية وتميز.
        </p>
      </div>
      <ServicesSection />
      <WhySection />
      <AdvantagesSection />
      <CustomersSection />
    </>
  );
};

export default HomePage;
