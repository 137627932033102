import React, { useEffect } from 'react';
import AOS from 'aos';
import './style.css';
import 'aos/dist/aos.css';

const CarWashMainSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='section pad car_wash' data-aos='fade-left'>
      <div className='container'>
        <div className='row car_hero'>
          <div className='col-sm-9 col-md-9'>
            <p className='lead'>
              نقدم خدمات غسيل وتلميع المركبات بواسطة فريق متدرب بمهارات وخبرات
              عالية.
            </p>
            <p>
              وذلك من خلال أسطول متنقل يخدم العمال في مواقعهم كمواقف السيارات
              والأسواق والمراكز والشركات بتكلفة بسيطة وجهد أقل، ويكون العمل
              بمنتجات آمنة وصحية من خلال استخدام كميات قليلة جداً من الماء
              وبمنتجات ذات فعالية عالية في التنظيف والتلميع، وتعد البديل الأنسب
              للماء والصابون، وتمتلك القدرة على التلميع الاحترافي وإزالة جميع
              البقع.
            </p>
          </div>
          <div className='col-sm-3 col-md-3'>
            <div className='jumbo-heading'>
              <h2>تميز بلمعــان سيارتــك</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarWashMainSection;
