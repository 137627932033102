import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Footer from './components/Footer';
import Header from './components/Header';
import Loader from './components/Loader';
import { PATHS } from './constants/paths';
import ContactPage from './Pages/ContactPage';
import AboutPage from './Pages/AboutPage';
import CarWashPage from './Pages/CarWashPage';
import CarQueuingPage from './Pages/CarQueuing';
import { useEffect } from 'react';
import FAQPage from './Pages/FAQPage';

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Loader />
      <Header />
      <Routes>
        <Route path={PATHS.HOME} element={<HomePage />} />
        <Route path={PATHS.ABOUT} element={<AboutPage />} />
        <Route path={PATHS.CONTACT} element={<ContactPage />} />
        <Route path={PATHS.SERVICES.CAR_WASH} element={<CarWashPage />} />
        <Route path={PATHS.SERVICES.CAR_QUEUING} element={<CarQueuingPage />} />
        <Route path={PATHS.FAQ} element={<FAQPage />} />
        <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
