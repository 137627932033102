import React from 'react';

const WhyItem = ({ title, image, desc }) => {
  return (
    <div className='box-icon-3'>
      <div className='icon'>
        <img src={image} alt={title} />
      </div>
      <div className='infobox'>
        <h5 className='title'>{title}</h5>
        <div className='text'>{desc}</div>
      </div>
    </div>
  );
};

export default WhyItem;
