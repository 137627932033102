import React, { useEffect } from 'react';
import AOS from 'aos';
import WhyItem from './WhyItem';
import './style.css';
import { data } from '../../../../mock/data';
import 'aos/dist/aos.css';

const WhySection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className='section mtb20 why '>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 '>
            <h2 className='section-heading'> لماذا أميال خدمات؟</h2>
            <div className='why_container' data-aos='fade-up'>
              {data.map((item) => (
                <WhyItem
                  key={item.title}
                  image={item.image}
                  title={item.title}
                  desc={item.desc}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection;
