export const data = [
  {
    image: '/assets/images/apperance-cader.png',
    title: 'المظهر الخاص بالكـــــــــــــــــــــــــادر',
    desc: 'تم اختيار زي موحد للكادر العامــــــــــــــــــــل بالمشروع والذي نهتم بأدق التفاصيل فيه مـن التصميم والألوان والنظافة، لنعبر عن االحتراف والجمــــــــــــــــــــــــــــــــــــــــــال.',
  },
  {
    image: '/assets/images/cleaner.png',
    title: 'جودة الأدوات والمــــــــــــــــــــــــواد',
    desc: 'نظافة فائقة، ومنتجات التنــــــــظيف آمنة وصديقة للبيئة، ومنتجـــــــــــــــات الغسيل قابلة للتحلل الحيـــــــــــــــــــــــــــــــــــــــوي.',
  },
  {
    image: '/assets/images/car-cleaner.png',
    title: 'عـــــربة الغسيل',
    desc: 'المظهر الخاص بالعربة يتميز بالسهولة والسرعة في التنقل ومزودة بأحدث التقنيات الخاصة لضخ المياه والتي تكفي محيط موقع غسيل السيـــــــــــــــــــــــــــــــــــــارة.',
  },
  {
    image: '/assets/images/car-apperance.png',
    title: 'الاهتمام والنظافة',
    desc: 'الاهتمام  بنظافة الأماكن العامة في مكان تقديم الخدمة وعدم ترك أثر بعد عملية الغسيل, غسل السيارة ب 1,5 لتر من الماء فقط.',
  },
];
