import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    loading && (
      <div className='animationload'>
        <div className='loader'></div>
      </div>
    )
  );
};

export default Loader;
