import React from 'react';
import FacSection from '../../components/sections/FaqPage';
import BreadCrumb from '../../components/BreadCrumb';

const FAQPage = () => {
  return (
    <>
      <BreadCrumb page={'الشروط والأحكام'} />
      <FacSection />
    </>
  );
};

export default FAQPage;
