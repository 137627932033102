import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import AboutHero from '../../components/sections/AboutPage/ِAboutHero';
import CustomersSection from '../../components/sections/HomePage/CustomersSection';

const AboutPage = () => {
  return (
    <>
      <BreadCrumb page={'من نحن'} />
      <AboutHero />
      <CustomersSection />
    </>
  );
};

export default AboutPage;
