import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import './style.css';

const AboutHero = () => {
  return (
    <div className='section pad about_hero'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6 col-md-6'>
            <div className='box'>
              <img
                src='/assets/images/about3.jpeg'
                alt=''
                className='img-responsive'
              />
            </div>
          </div>
          <div className='col-sm-6 col-md-6 text_padding'>
            <h3>أمـــيـــــال خدمات</h3>
            <p className='lead'>
              شركة تندرج من مجموعة شركات أميال الذكية بمسمى أميال خدمات، وتخصصت
              في تقديم مختلف الخدمات للعناية في المركبات التي تتميز في غسيل
              السيارات المتنقل
            </p>
            <p>
              نتميز بتشغيل الأيدي العاملة المدربة التي من خلالهم استطاعت الشركة
              إبرام عقود مع شركات تفخر بالتعامل معهم في كامل مناطق المملكة وأهم
              العملاء شركة رينو للسيارات، جاك موتورز، شيفروليه، كاديلاك، جي أم
              سي، انتربرايز، وغيرهم من العملاء المميزين.
            </p>
            <div className='margin-bottom-50 '></div>
            <div className='d_f'>
              <Link
                to={PATHS.CONTACT}
                className='btn btn-secondary f_center'
                title=''>
                تواصل معنا
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
