import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import CarQueuingMain from '../../components/sections/CarQueuingPage';

const CarQueuingPage = () => {
  return (
    <>
      <BreadCrumb page='خدمة الڤاليه (اصطفاف المركبات)' />
      <CarQueuingMain />
    </>
  );
};

export default CarQueuingPage;
