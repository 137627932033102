export const PATHS = {
  HOME: '/',
  SERVICES: {
    ROOT: '/services',
    CAR_WASH: '/car-wash',
    CAR_QUEUING: '/car-queuing',
  },
  ABOUT: '/about',
  CONTACT: '/contact',
  FAQ: '/faq',
};

export const SEND_EMAIL_API =
  'https://api-send-email-zrwy.onrender.com/send-email';
