/* eslint-disable react/jsx-no-target-blank */
import React, { useRef, useState } from 'react';
import './style.css';
import { IoIosCall } from 'react-icons/io';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${day}-${month}-${year}`;

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [valid, setValid] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const emailValidate = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(value)) {
      setErrors({
        ...errors,
        email: '',
      });
      return true;
    } else {
      setErrors({
        ...errors,
        email: 'الرجاء ادخال بريد الكتروني صحيح',
      });
      return false;
    }
  };

  const nameValidate = (value) => {
    const regex = /^[a-zA-Z ]*$/;
    if (regex.test(value)) {
      setErrors({
        ...errors,
        name: '',
      });
      return true;
    } else {
      setErrors({
        ...errors,
        namse: 'الرجاء ادخال الاسم',
      });
      return false;
    }
  };

  const phoneValidate = (value) => {
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value.length === 9) {
      setErrors({
        ...errors,
        phone: '',
      });
      return true;
    } else {
      setErrors({
        ...errors,
        phone: 'الرجاء ادخال رقم جوال صحيح',
      });
      return false;
    }
  };
  const onChangeHandler = {
    name: (e) => {
      setName(e.target.value);
      setValid({ ...valid, name: nameValidate(e.target.value) });
    },
    email: (e) => {
      setEmail(e.target.value);
      setValid({ ...valid, email: emailValidate(e.target.value) });
    },
    phone: (e) => {
      setPhone(e.target.value);
      setValid({ ...valid, phone: phoneValidate(e.target.value) });
    },
  };

  const checkEmptyField = () => {
    setErrors({
      name: name === '' ? 'الرجاء ادخال الاسم' : '',
      email: email === '' ? 'الرجاء ادخال بريد الكتروني صحيح' : '',
      phone: phone === '' ? 'الرجاء ادخال رقم جوال صحيح' : '',
    });
  };

  const resetField = () => {
    nameRef.current.value = '';
    emailRef.current.value = '';
    phoneRef.current.value = '';
  };

  const sendData = (e) => {
    e.preventDefault();
    checkEmptyField();
    if (!valid.phone) {
      phoneRef.current.focus();
    }
    if (!valid.email) {
      emailRef.current.focus();
    }
    if (!valid.name) {
      nameRef.current.focus();
    }

    if (valid.name && valid.email && valid.phone) {
      emailjs
        .sendForm(
          'service_x26wlfg',
          'template_nt1lncb',
          e.target,
          'GuwAZOGKtywSfVOeI'
        )
        .then(
          (result) => {
            resetField();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: '',
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: '',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
    }
  };

  return (
    <div className='section contact_section '>
      <div className='container '>
        <div className='form_content'>
          <form onSubmit={sendData}>
            <div className='form_group'>
              <label htmlFor='name'>
                الاسم <span>*</span>{' '}
              </label>
              <input
                id='name'
                value={name}
                type='text'
                placeholder=''
                name='name'
                onChange={onChangeHandler.name}
                ref={nameRef}
              />
              {errors.name && <p>{errors.name}</p>}
            </div>

            <div className='form_group'>
              <label htmlFor='email'>
                البريد الالكتروني <span>*</span>{' '}
              </label>
              <input
                id='email'
                ref={emailRef}
                value={email}
                type='email'
                placeholder=''
                name='email'
                onChange={onChangeHandler.email}
              />
              {errors.email && <p>{errors.email}</p>}
            </div>
            <div className='form_group'>
              <label htmlFor='phone'>
                رقم الجوال
                <span> *</span>{' '}
              </label>{' '}
              <div className='div_number' dir='ltr'>
                <span>+966 </span>
                <input
                  ref={phoneRef}
                  id='phone'
                  value={phone}
                  onChange={onChangeHandler.phone}
                  type='number'
                  style={{ paddingLeft: '4rem' }}
                  name='phone'
                />
              </div>
              {errors.phone && <p>{errors.phone}</p>}
              <input hidden name='date' defaultValue={currentDate} />
            </div>
            <input type='submit' value='إرسال' />
          </form>
          <div className='contact_details'>
            <div className='contact_icon'>
              <img src='/assets/images/logo-remove-background.png' alt='' />
            </div>

            <div className='mobile_number'>
              <a target='_blank' href={'tel:+966595482309'}>
                <span>
                  <IoIosCall />
                </span>
                <p> 2309 548 59 966+</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
