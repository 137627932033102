import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const CarQueuingMain = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='section pad car_wash ' data-aos='fade-left'>
      <div className='container'>
        <div className='row car_hero'>
          <div className='col-sm-9 col-md-9 col-lg-9'>
            <p className='lead'>
              يتم توفير فريق خاص في إدارة وتنظيم عملية اصطفاف المركبات، ذو خبرة
              في التعامل مع كافة أنواع المركبات، والفريق مدرب على السلامة العامة
              حسب الشروط والمواصفات الفنية.
            </p>
            <p>
              في أميال سيرفس نسعى لتقديم تجربة اصطفاف استثنائية عالية الجودة من
              خلال خدمة "صف السيارات" والتي نهدف فيها لزيادة رضى العميل وبناء
              جسور وصل دائمة بين المكان والزائر من خلال الخدمة التي تفوق
              التوقعات.
            </p>
          </div>
          <div className='col-sm-3 col-md-3 col-lg-3'>
            <div className='jumbo-heading'>
              <h2> تنظيم عملية اصطفاف المركبات </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarQueuingMain;
