import React, { useEffect } from 'react';
import AOS from 'aos';
import './style.css';
import AdvItem from './AdvItem';

import 'aos/dist/aos.css';

const AdvantagesSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='section pt40 pb50' data-aos='fade-up'>
      <div className='container'>
        <h2 className='section-heading'> مميزاتنـــــا </h2>
        <div className='adv_section' data-aos='fade-up'>
          <AdvItem
            title='المنصة المضيئة: '
            desc='نوفرها في مكان تقديم صف السيارات نهدف فيها إلى لفت انتباه الزوار
              إلمكانية طلب الخدمة واحترافية العمل وترك طابع أفضل عن المكان.'
          />
          <AdvItem
            title={'الـــــــــــــــزي الموحد:'}
            desc='يتميز فريق السائقين بزي موحد أنيق
يتناسب مع المكان.'
          />
          <AdvItem
            title={'تقنية المعلومات:'}
            desc='نهتم باحترافية العمل من خلال تقديم جهاز يمكن الزوار من طلب سياراتهم قبل الخروج من المكان اختصاراً للوقت والجهد.'
          />
          <AdvItem
            title={'الــــــتأمين:'}
            desc='حريصون دائمًا لتفادي المخاطر فلدينا تأمين
شامل ضد الأخطاء المدنية وحوادث السيارات
الواقعة من طرف موظفي شركة أميال.'
          />
        </div>
      </div>
    </div>
  );
};

export default AdvantagesSection;
