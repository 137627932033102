import React from 'react';

const ServiceCard = ({ title, desc, image, id, onClick }) => {
  const handler = (num) => {
    onClick(num);
  };
  return (
    <div className='col-sm-6 col-md-6 col-lg-6' onClick={() => handler(id)}>
      <div className='box-image-1'>
        <div className='media'>
          <img
            style={{ width: '100%' }}
            src={image}
            alt=''
            className='img-responsive'
          />
        </div>
        <div className='body'>
          <h1 className='title h3'>{title}</h1>
          <p>{desc}</p>
          <span className='readmore '> مشاهدة المزيد</span>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
