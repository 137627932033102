import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import CarWashMainSection from '../../components/sections/CarWashPage';

const CarWashPage = () => {
  return (
    <>
      <BreadCrumb
        page='خدمة
غسيل
السيارات'
      />
      <CarWashMainSection />
    </>
  );
};

export default CarWashPage;
