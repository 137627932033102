import React from 'react';
import './style.css';

const FacSection = () => {
  return (
    <div className='section pt20'>
      <div className='container'>
        <div className='row'>
          <h2 className='faq_title'>اتفاقية الشروط والأحكام</h2>
          <p className='faq_desc'>
            {' '}
            أميال سيرفس منصة أنشئت من أجل تقديم خدمات(غسيل + صف) المركبات،
            باستخدامك لمنصة أميال سيرفس فإنك توافق على هذه الاتفاقية من غير قيود
            أو شروط، ويحق لمنصة أميال سيرفس في أي وقت تعديل هذه الاتفاقية وسيتم
            نشر النسخة المعدلة على المنصة، وتكون النسخة المعدلة من(اتفاقية
            الشروط والأحكام) ساريةالمفعول بعد أسبوع من تاريخ نشرها في المنصة،
            وبعد النشر يعتبر استمرارك في استخدام المنصة، التزاماً منك بالشروط
            والبنود الواردة في النسخة المعدلة، ورضاً منك في تطبيق التعديلات.
          </p>
          <div className='faq_item'>
            <div className='faq_item_title'>
              أولاً: : يقصد بالمصطلحات التالية متى ما وردت في هذه الاتفاقية –
              اتفاقية الشروط والأحكام - ما يلي:
            </div>
            <ol className='faq_item_desc'>
              <li>
                <span>المستخدم:</span> أي شخص أو جهة يستخدم منصة أميال سيرفس
                للاطالع والاستفادة من خدماتها.
              </li>
              <li>
                <span>المنصة: </span> يقصد بها أينما وردت في هذه الاتفاقية أو في
                سياسة الخصوصية "تطبيق أميال سيرفس" ما لم يكن سياق النص لمعنى
                آخر.
              </li>
              <li>
                <span>الحساب: </span> هو الصفحة/السجل الخاص بالمستخدم المنشأ من
                أجل أن يتمكن المستخدم من الاستفادة من خدمات منصة أميال سيرفس.
              </li>
              <li>
                <span>الخدمة / الخدمات: </span> هي جميع الخدمات المتاحة في منصة
                أميال سيرفس (غسيل المركبات +صف المركبات+وأي خدمات يتم إضافتها في
                المنصة).
              </li>
              <li>
                <span>صف المركبات:</span> خدمةتقدم من خلال المنصة وصورتها أن
                توفر المنصة من خلال موقع معين (مطعم أو مستشفى أو مجمع تجاري أو
                غيرها من المواقع) مقر يتواجد فيه عمال تابعين لها لاستقبال طلبات
                المستخدمين المتواجدين في نفس الموقع من خلال المنصة ومن ثم يتم
                استلام المركبات وصفها في المواقف، وبعد ذلك يتم إرجاعها وتسليمها
                للمستخدم عند طلبها من خلاله.
              </li>
              <li>
                <span>غسيل المركبات: </span> خدمة تقدم من خلال المنصة وصورتها أن
                يطلب المستخدم من خلال المنصة خدمة غسيل المركبة ويتم بعدها حضور
                أحد العمال التابعين للمنصة بالعربات المخصصة لغسيل المركبات في
                الموعد و الموقع الذي حدده المستخدم لتقديم الخدمة.
              </li>
            </ol>
          </div>

          <div className='faq_item'>
            <div className='faq_item_title'>
              ثانياً :شروط تسجيل المستخدم في منصة أميال سيرفس:
            </div>
            <ol className='faq_item_desc'>
              <li>
                يجب أن يكون مكتسب للأهليةالكاملة وأن يكون عمره يتجاوز 18 سنة،
                ويقر المستخدم أنه مكتسب للأهلية الكاملة.
              </li>
              <li>
                يلتزم المستخدم بتزويد منصة أميال سيرفس بالمعلومات والبيانات
                الصحيحة المطلوبة للاستفادة من خدمات المنصة.
              </li>
              <li>
                يحظر على المستخدم القيام بأي عمل قد يؤدي إلى حدوث ضغط غير محتمل
                أو غير متناسب مع المنصة أو البنية التحتية الخاصة
              </li>
              <li>
                يحظر على المستخدم نشر أو تحميل أي مواد تشهيرية، هجومية،
                انتهاكية، مضرة، تهديدية، مسيئة، ملتوية أو عنصرية، أو غير مالئمة
                ، أخالقياً أو غير ذلك من محتوى أو معلومات غير قانونية.
              </li>
              <li>
                يحظر على المستخدم الدخول أوالقيام بمحاولةدخول غير مصرح به
                للمنصةأومنح الآخرين صالحيةاستخدام حسابه، أوانتحال أي شخصية أو
                تحريف العضوية مع شخص آخر، ويتحمل المستخدم ما يترتب على مخالفته
                لأحكام هذه الفقرة.
              </li>
              <li>
                يحظر على المستخدم القيام بعمل من الممكن أن يتسبب بالضرر أو
                يتضارب مع حقوق الملكيةالفكرية للمنصة.
              </li>
              <li>
                يحظر على المستخدم تحميل أوإرفاق أي ملفات أو برامج أوأي موادأخرى
                محمية من قبل قوانين حقوق الملكيةالفكريةأوأي حقوق خاصةأو عامة لا
                يملك حقوق استخدامها.
              </li>
              <li>
                إذا تبين للمنصة يوفقاً لتقديرها الشخصي قيام المستخدم بخرق
                أومخالفة أي من هذه الشروط، فلها حق حذف حساب المستخدم أو إيقافه و
                تعليق أو تقييد وصول المستخدم أو منعه من استخدام المنصة،وللمنصة
                الحق في التبليغ عن المستخدم لدى مزودي خدمة الإنترنت أو السلطات
                المختصة، أو اتخاذ أي إجراءات قانونية.
              </li>
            </ol>
          </div>

          <div className='faq_item'>
            <div className='faq_item_title'>
              ثالثاً :أحكام الاستفادة من خدمات منصةأميال سيرفس:
            </div>
            <ol className='faq_item_desc'>
              <li>
                تقدم المنصة خدماتها في الموقع الذي سيتم تحديده من قبل المستخدم
                بحسب اختياره لنوع الخدمة وفئاتها وأنواعها، و بحسب القيمة المقررة
                في المنصة لكل نوع من أنواع الخدمات المتاحة في المنصة.
              </li>
              <li>
                تنفذ الخدمات وفقاً لمسماها في المنصة أو الوصف المرفق بها - إن
                وجد- وبحسب الآلية والطريقة التي تعتمدها المنصة، ولن يكون لتوقعات
                المستخدم الغير صحيحة أي أثر على تقديم الخدمة أو أي مسؤولية على
                المنصة تجاه الخدمة المقدمة، وُيمكن للمستخدم تلافياً لأي إشكال
                الاستفسار من خلال التواصل مع خدمة العمالاء أو إدارة المنصة قبل
                شراء أي من الخدمات المُقدمة وإلا عُد ملزماً بالخدمة وفق ما تقدمه
                المنصة.
              </li>
              <li>
                يقر المستخدم بمنحه للمنصة صالحيةالوصول وتحديد الموقع الخاص به
                لكي يتم تقديم الخدمة بالشكل المطلوب.
              </li>
              <li>
                يلتزم المستخدم بتحديد الموقع الصحيح الذي سُتقدم فيه الخدمة من
                خلال الخرائط والأيقونات التي سُتتاح في المنصة لتحديد الموقع .
              </li>
              <li>
                يلتزم المستخدم بسداد قيمة الخدمة عبر وسائل الدفع المتاحة في
                المنصة قبل البدء في تنفيذ الخدمة، ويحق للمنصة عدم قبول الطلب أو
                تنفيذه حتى يتم سداد كامل قيمة الخدمة، على أنه لا يعني تقديم
                الخدمة قبل سداد المستخدم لقيمتها إعفائه من السداد ويجب عليه
                الالتزام بسداد القيمة المستحقة.
              </li>
              <li>
                يلتزم المستخدم بالحضور في الموقع والموعد التي حددها في المنصة
                عند طلب الخدمة، كما يلتزم بتمكين العمال التابعين للمنصة من أداء
                الخدمة المطلوبة في الوقت المحدد لتقديم الخدمة، وفي حال تأخره عن
                التواجد في الموعد أو الموقعاالمُحددة لتقديم الخدمة لأكثر من
                (15دقيقة) فإنه سيتم اعتبار الخدمة تم تقديمها وتستحق المنصة كامل
                قيمة الخدمة، وسيتم مغادرة الموقع من قبل العمال التابعين لها.
              </li>
              <li>
                يلتزم المستخدم بإيقاف المركبة في الموقع المحدد في المنصة لتقديم
                الخدمة، ويجب أن يكون هذا الموقف مناسباً لتقديم الخدمة المطلوبة،
                وفي حال عدم مناسبة الموقف لتقديم الخدمة فسيتم إبلاغ المستخدم
                بتغيير موقف المركبة حالاً وفي حال مرور (15دقيقة) دون استجابته
                وتغييره لموقف المركبة فإنه سيتم اعتبار الخدمة تم تقديمها وتستحق
                المنصة كامل قيمة الخدمة، وسيتم مغادرةالموقع من قبل العمال
                التابعين لها.
              </li>
              <li>
                لا يحق للمستخدم التعديل على الموعد أو الموقع المحددة في المنصة
                لتقديم الخدمةالمطلوبة.
              </li>
              <li>
                يتعهد المستخدم قبل تسليم المركبة أو فتحها للعامل التابع للمنصة
                بإفراغها من أي أغراض أومقتنيات أو غير ذلك مما يمكن وقوع أي ضرر
                أوفقدان له، ولن تتحمل المنصة ولا العاملين التابعين لها أي
                مسؤوليات تجاه أي أغراض أو مقتنيات موجودة بالمركبة تعرضت للتلف أو
                الفقدان أثناء تسليم المركبة للعامل التابع للمنصة وبقائها معه حتى
                الانتهاء من تنفيذ الخدمة المطلوبة.{' '}
              </li>
              <li>
                ستقوم المنصة بتقديم الخدمة المطلوبة منها على الوجه المعتاد عرفاً
                في تقديم هذه الخدمات ولن تتحمل المنصةأي مسؤولية تجاه التلفيات أو
                الأضرار التي قد تقع على المستخدم أو مركبته أثناء تقديم الخدمة
                بسبب خارج عن إرادتها ودون وجود تعد أوتفريط منها ومن ذلك على سبيل
                المثال لا الحصر ( تلفيات أو أضرار غير مقصودة تقع في بوية المركبة
                أو جلودها أو أي من أجزائها بسبب خدمة غسيل المركبة – أي حوادث أو
                ضربات أو مخوش أو أي تلفيات قد تقع على المركبة أثناء تقديم خدمة
                صف المركبات بسبب خطأ طرف آخر غيرالمنصة أو العامل التابع لها – أو
                غير ذلك ).
              </li>
              <li>
                يلتزم المستخدم بإبلاغ المنصة بشكل رسمي والعمال التابعين لها ورفع
                طلب خاص في المنصة للإفصاح عن أي تجهيزات أو معدات أو تعديلات على
                المركبة يترتب عليها استخدام المركبة أو تقديم الخدمة بشكل خاص
                وقيادتها بطريقة خاصة عند القيام بخدمة صف المركبات
              </li>
              <li>
                بعد الانتهاء من تقديم الخدمة سيتم إبلاغ المستخدم للحضور واستلام
                المركبة، وفي حال تأخره لأكثر من ( 5دقائق) عن الحضور والاستلام،
                فسيتم مغادرة الموقع من قبل العامل التاب ع للمنصة، ويقر المستخدم
                بعدم تح مل المنصة ولا العامل التابع لها أي مسؤوليات تجاه مركبته
                وما فيها .
              </li>
              <li>
                في خدمة صف المركبات يلتزم المستخدم بالحضور لاستلام مركبته حدد من
                المنصة لاستخدام الخدمة، وفي حال عدم ُ في الموعد الم تحديد ساعات
                محددة من قبل المنصة فيلتزم بالحضور قبل موعد نهاية الدوام الرسمي
                للموقع الذي تقدم فيه الخدمة ( المطعم أو المستشفى أو المجمع أو
                غيره( ب) 15 دقيقة) وفي حال تأخره فلا تتحمل المنصة أي مسؤوليات
                تجاه مركبته أو بقائها لليوم التالي، وسيغادر العمال التابعين
                للمنصة دون أدن ى مسؤولية.
              </li>
              <li>
                سيكون استلام مفتاح المركبة من قبل المستخدم بعد الانتهاء من خدمة
                صف المركبات والمغادرة بها من المقر التابع للمنصة ً إقرارا منه
                بسلامة المركبة واستلامها بنفس الحالة التي تم التسليم بها .
              </li>
              <li>
                يج ب على المستخدم المحافظة على البطاقة أو الكود الذي سيتم تسليمه
                له من قبل المنصة أو التابعين لها عند استلام المركبة في المركبات
                خدمة صف ، وسيكون تسليم المركبة للمستخدم بعد الانتهاء من الخدمة
                بموجب البطاقة أو الكود، وسيتح مل المستخدم مسؤولية فقدان البطاقة
                أو الكود واستخدامها من قبل أطراف آخرين لاستلام مركبته ويقر
                المستخدم بعدم تح مل المنصة أي مسؤولية.
              </li>
              <li>
                يجب على المستخدم إبلاغ المنصة وإبلاغ التابعين لها عن فقدانه
                البطاقة أو الكود الذي تم تسليم المركبة من خلاله في خدمة صف ً
                المركبات وإلا ع د مسؤولا عن أي مما يحدث على مركبته باستخدام
                الكود أو البطاقة من قبل طرف آخر ولن تتحمل المنصة أي مسؤولية تجاه
                المستخدم وتعرض مركبته لأي سرقة أو ضرر.
              </li>
              <li>
                خدمة غسيل المركبات لا تتطلب من المستخدم تسليم مفتاح المركبة
                للعامل التابع للمنصة، وعليه فلا تتحمل المنصة ولا العامل التابع
                لها أي مما ينتج من مخالفات أو تجاوزات بسبب تسليم المستخدم مفتاح
                المركبة للعامل.
              </li>
              <li>
                يحق للمنصة رفض تقديم الخدمة، وعدم استرداد قيمتها للمستخدم في حال
                وجود اختلاف في المعلومات بين المركبة حددة في ُ الم المنصة
                والمركبة التي سيتم تقديم الخدمة من خلالها.
              </li>
              <li>
                يحق للمنصة ولأي سبب كان إلغاء الطلب المقدم من المستخدم وعدم
                تقديم الخدمة، وسيكون على المنصة استرداد القيمة المدفوعة للمستخدم
                .
              </li>
              <li>
                يلتزم المستخدم بالتعليمات الصادرة من الجهات الرسمية بالإلتزام
                بتأمين المركبة، كما يلتزم بالإفصاح للمنصة عند طلب الخدمة في حال
                عدم وجود تأمين لمركبته، وإلا فإنه سيتحمل المسؤولية التامة عن أي
                مما سينشأ على مركبته أو على مركبات الأطراف الأخرى.
              </li>
              <li>
                في حال وقع حادث مروري أو ضرر على المركبة بسبب تفريط من المنصة أو
                التابعين لها وكانت مركبة المستخدم يوجد عليها تأمين يُمكن أن يتح
                مل إصلاح الضرر الذي وقع على مركبة المستخدم أو مركبة أي أطراف
                أخرى في الحادث، فلن تتحمل المنصة أي تعويضات أو تكاليف ويجب على
                المستخدم الاستفادة من التأمين، ولن يقبل من المستخدم محاولة
                الإضرار بالمنصة وإيجاد أي مبررات للتأمين لعدم تحمل الأضرار
                الواقعة على مركبته وعلى الطرف الآخر.
              </li>
              <li>
                لن تتحمل المنصة ولا العمال التابعين لها مسؤولية وجود أي ممنوعات
                أو مواد محظورة أو أي مخالفات أخرى موجودة في مركبة المستخدم، وأي
                موجودات محظورة أو مخالفة يتم ضبطها فستكون تابعة للمستخدم ويتحمل
                المسؤولية الكاملة تجاهها .
              </li>
              <li>
                لن تتحمل المنصة أي مخالفات يتم فرضها من قبل أي جهة بسبب الخدمات
                المقدمة للمستخدم وما ينتج عنها والتي منها على سبيل المثال لا
                الحصر ( المخالفات المتعلقة بخروج الماء عند الغسيل أو برمي
                النفايات أو الوقوف الخاطئ أو غير ذلك ) .
              </li>
              <li>
                يلتزم المستخدم بكافة أحكام وشروط هذه الاتفاقية وفي حال قيام
                المستخدم بتصرف يخالف ما ورد في هذه الاتفاقية فيحق للمنصة تعطيل
                استفادته من خدماتها في أي وقت دون سابق إشعار، كما يحق للمنصة
                اللجوء للجهة المختصة لمطالبة المستخدم بالتعويض عن أي ضرر .
              </li>
              <li>
                يتحمل المستخدم المسؤولية الكاملة لوحده عند قيامه بأي مخالفة
                لأحكام هذه الاتفاقية، أو مخالفة توجيهات المنصة، أو العمال
                التابعين لها .
              </li>
            </ol>
          </div>

          <div className='faq_item'>
            <div className='faq_item_title'>رابعا : خدمات الدفع:</div>
            <ol className='faq_item_desc'>
              <li>
                يجب على المستخدم القيام بالسداد من خلال وسائل الدفع المتاحة في
                المنصة قبل مباشرة تقديم الخدمة من قبل المنصة .
              </li>
              <li>
                يحظر على المستخد م الاحتيال في استخدام وسيلة دفع غير صحيحة أو
                غير صالحة، ويمنع كذلك من استخدام وسيلة دفع مسروقة ويتحمل
                المستخدم المسؤولية نتيجة مخالفة هذه الفقرة .
              </li>
              <li>
                جميع عمليات الدفع تتم بالريال السعودي، وفي حال قام المستخدم
                بالدفع باستخدام عملة أخرى فيتحمل النفقات والأجور المترتبة على
                تحويل العملة أو أي رسوم بنكية أخرى.
              </li>
              <li>
                يحق للمستخدم المطالبة بإلغاء الخدمة واسترجاع قيمتها المدفوعة
                بشرط أن يقدم الطلب قبل موعد الخدمة ب ( 48 ساعة) مباشرة، وفي هذه
                الحالة وفي أي حالات استرجاع للمستخدم يُ سترجع المبلغ المدفوع
                للمستخدم مع خصم رسوم الاسترجاع المفروضة من وسيط الدفع الإلكتروني
                أو من أي جهة ذات علاقة.
              </li>
              <li>
                يجب أن يقدم طلب إلغاء الخدمة من خلال قنوات التواصل المحددة داخل
                المنصة.
              </li>
            </ol>
          </div>

          <div className='faq_item'>
            <div className='faq_item_title'>خامسا: أحكام عامة :</div>
            <ol className='faq_item_desc'>
              <li>
                يحق للمنصة تزويد مقدم الخدمة أو الجهات المختصة بالمعلومات
                الشخصية للمستخدم بما في ذلك الاسم وبيانات الهوية، وبيانات السجل
                التجاري والعنوان وذلك حال قيام المستخدم بمخالفة شروط وأحكام
                المنصة، أو عند الحاجة لتقديم بلاغ أو تسجيل شكوى عليه.
              </li>
              <li>
                ٢. وافق المستخدم على منح المنصة ح ق استخدام والتصرف بمعلومات ه
                في تطوير الخدمة من قبل المنصة أو أي عمليات تسوي ق لخدماتها أو
                لخدمات أي جهات أخرى تتعامل معها المنصة، وبالطريقة التي تقررها
                المنصة .
              </li>
            </ol>
          </div>
          <div className='faq_item'>
            <div className='faq_item_title'>سادسا: الإشعارات :</div>
            <p>
              تعد مراسلات منصة أميال سيرفس على العناوين الرسمية المدونة في صفحة
              التسجيل الخاصة بالمستخدم أو الإشعارات التي تتم عبر التطبيق إشعارات
              قانونية منتجة لآثار التبليغ .
            </p>
          </div>
          <div className='faq_item'>
            <div className='faq_item_title'>سابعا: التقاض ي وحل النزاعات :</div>
            <p>
              تسري أنظمة وقوانين المملكة العربية السعودية على أحكام هذه
              الاتفاقية، وتختص الجهة المختصة بمدينة الرياض بنظر أي نزاع ينشأ عن
              تطبيق هذه الاتفاقية، وللمنصة الحق بمقاضاة خصومها في مقر إقامتهم
              أمام الجهات المختصة.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacSection;
