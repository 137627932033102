import React from 'react';
import { PATHS } from '../../constants/paths';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ page }) => {
  return (
    <div className='section banner-page'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-12'>
            <div className='title-page'> أمـــيـــــال خدمات</div>
            <ol className='breadcrumb'>
              <li>
                <Link to={PATHS.HOME}>الرئيسية</Link>
              </li>
              <li className='active'>{page} </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
