import React from 'react';
import './style.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';

const CustomersSection = () => {
  return (
    <div className='section partner pt20 pb20 '>
      <h2 className='section-heading customers_title'> عملاؤنا </h2>

      <div className='container'>
        <Swiper
          dir='rtl'
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          width={850}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className='customers'>
          <SwiperSlide className='slide'>
            <img src='/assets/images/jamih.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/jazeeraPaints.svg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/ptromen.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/holiday-in.jpg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img
              src='/assets/images/alhakair.png'
              alt=''
              style={{ height: 'auto' }}
            />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/STELLANTIS.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/j&t.png' alt='' />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className='container'>
        <Swiper
          dir='ltr'
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          width={850}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className='customers'>
          <SwiperSlide className='slide'>
            <img src='/assets/images/nissan.jpg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/sasco.gif' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/tayseer.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/frasers.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/Aster_Sanad_Saudi.svg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/jeep.jpg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/renault.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/Samara_logo.png' alt='' />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className='container'>
        <Swiper
          dir='rtl'
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          width={850}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className='customers'>
          <SwiperSlide className='slide'>
            <img src='/assets/images/GMC.svg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/gac-motors.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img
              src='/assets/images/opel.png'
              alt=''
              style={{ height: '100%' }}
            />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img
              src='/assets/images/fiat.png'
              alt=''
              style={{ height: '100%' }}
            />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/autorent.webp' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/DS_Automobiles.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/sixt.png' alt='' />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className='container'>
        <Swiper
          dir='ltr'
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          width={850}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className='customers'>
          <SwiperSlide className='slide'>
            <img src='/assets/images/alqarwiya.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/alfaqieh.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/alnassar.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/alfardan.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/abyat.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <img src='/assets/images/alakaria.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/abarth.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/alfa-romeo.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/citroen.svg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/OIP.jpg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/e.png' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/peugeot.jpeg' alt='' />
          </SwiperSlide>
          <SwiperSlide className='slide background'>
            <img src='/assets/images/cadillac.webp' alt='' />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CustomersSection;
